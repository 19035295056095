<template>
    <div class="container d-flex flex-column align-items-center text-center">
        <div class="display-6 mb-5">Ringe & Omegns Biavlerforening</div>
        <div class="">
            kommer snart...
        </div>
    </div>
</template>

<script>
    export default {
        name: "about-route",
    }
</script>

<style>

</style>