const CALENDAR = 'Calendar'
const RECIPE = 'Recipe'
const COMMITEEMEMBER = 'CommitteeMember'

export default {
    CALENDAR,
    RECIPE,
    COMMITEEMEMBER 
}

